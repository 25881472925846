import { ActionIcon, Button, Modal, Table, Text } from "@mantine/core";
import { IconEdit, IconEye, IconHistory, IconTrash } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { deleteObligation, getObligationsForAgreement } from "../apis/userApis";
import { getActFrequencyText, getDepartmentText } from "./utilities";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

const LegacyAgreementForm3 = ({ setUiConfigs, details }) => {
  const [obligations, setObligations] = useState(null);
  const navigate = useNavigate();
  console.log(details);
  const getObligationsforAgreement = async () => {
    const response = await getObligationsForAgreement({
      local: details.local,
      agreementId: details.agreementId,
    });
    console.log(response)
    setObligations(response.obligations);
  };
  useEffect(() => {
    
    getObligationsforAgreement();
  }, []);
  console.log(obligations)
  const onViewClick = (id) => {
    navigate(`/viewmasterobligation/${id}`);
  };
  const [showModal, setShowModal]= useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const deleteMasterObligation = async() =>{
    try {
      const response = await deleteObligation({id: deleteId});
      if (response.success) {
        setDeleteId(0);
        setShowModal(false);     
        getObligationsforAgreement();
      }
    } catch (error) {
      
    }
  }
  return (
    <div>

      <Modal
      opened={showModal}
      onClose={()=>{
        setShowModal(false);
        setDeleteId(0);
      }}
      >
        <div>
          <Text>are you sure you want to delete this obligation ?</Text>
          <div className="flex justify-between mt-4">
            <Button onClick={()=>{
              setDeleteId(0);
              setShowModal(false);
            }} color="gray" variant="light">Cancel</Button>
            <Button
            onClick={deleteMasterObligation}
             color="red">Delete</Button>
          </div>
        </div>

      </Modal>

      <Table striped>
        <thead className="text-center">
          <tr>
            {/* <th style={{ textAlign: "center" }}>select</th> */}
            <th style={{ textAlign: "center" }}>obligation id</th>
            <th style={{ textAlign: "center" }}>obligation title</th>
            <th style={{ textAlign: "center" }}>description</th>
            <th style={{ textAlign: "center" }}>act. frequency</th>
            <th style={{ textAlign: "center" }}>department</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {obligations &&
            obligations.map((row) => (
              <tr key={row.id}>
                {/* <td>
                  <input type="checkbox" />
                </td> */}
                <td>{row.id}</td>
                <td>{row.title}</td>
                <td>{row.desc}</td>
                <td>{getActFrequencyText(row.actFrequency)}</td>
                <td>{getDepartmentText(row.department)}</td>
                <td>
                  <div className="flex flex-row mr-4">
                    <ActionIcon
                      onClick={() => {
                        onViewClick(row.id);
                      }}
                      color="white"
                    >
                      <IconEye size={24} />
                    </ActionIcon>
                  </div>
                </td>
                <td>
                  <div className="flex flex-row mr-4">
                    <ActionIcon
                      onClick={() => {
                        setDeleteId(row.id);
                        setShowModal(true);
                      }}
                      color="white"
                    >
                      <IconTrash color="red" size={24} />
                    </ActionIcon>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <div className="flex flex-row justify-between">
        <Button
          style={{
            backgroundColor: "#46BDE1",
          }}
          onClick={() => setUiConfigs({ currentStep: 1 })}
          className="mt-3"
        >
          Previous
        </Button>
        <Button
          style={{
            backgroundColor: "#46BDE1",
          }}
          onClick={() => navigate('/docslocker')}
          className="mt-3"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default LegacyAgreementForm3;
