import React, { useState, useEffect } from "react";
import { getAssignedObligations } from "../apis/userApis";
import {
  ActionIcon,
  Badge,
  Modal,
  Pagination,
  Table,
  Text,
} from "@mantine/core";
import { IconEye, IconInfoCircle } from "@tabler/icons-react";
import { IconHistory } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import ObligationTranHistoryModal from "./ObligationTranHistoryModal";
import {
  formatDatetoISO,
  getStatusColor,
  getStatusText,
  infoColor,
} from "./utilities";
import lexend from "./Lexend-Black.ttf";
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from "../redux/authSlice";

const AssignedObligations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
const userData = JSON.parse(localStorage.getItem("userData"));
  const [ahModal, setAhModal] = useState(false);
  const [ahId, setAhId] = useState(0);
  const businessUnit = userData.businessUnit;
  const department = userData.department;
  const [configs, setConfigs] = useState({
    loading: true,
    obligations: [],
    count: 0,
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    const getObligations = async () => {
      const response = await getAssignedObligations({
        department: department,
        businessUnit: businessUnit,
      });
      console.log(response.obligations);
      setConfigs({
        loading: false,
        obligations: response.obligations,
        count: response.count,
      });
    };
    getObligations();
  }, []);

  const onViewClick = (id) => {
    navigate(`/viewobligation/${id}`);
  };
  return (
    <div style={{ fontFamily: lexend }}>
      <Text className="flex justify-center font-bold text-xl my-4">
        assigned obligations
      </Text>
      {!configs.loading && configs.obligations?.length > 0 && (
        <>
          <Table striped>
            <thead className="text-center">
              <tr>
                <th style={{ textAlign: "center" }}>select</th>
                <th style={{ textAlign: "center" }}>obligation id</th>
                <th style={{ textAlign: "center" }}>obligation title</th>
                <th style={{ textAlign: "center" }}>second party</th>
                <th style={{ textAlign: "center" }}>due date</th>
                <th style={{ textAlign: "center" }}>status</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {!configs.loading &&
                configs.obligations?.length > 0 &&
                configs.obligations
                  .slice((page - 1) * 10, page * 10 - 1)
                  .map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>
                          <input
                            type="checkbox"
                            id={`select-${row.id}`}
                            // onChange={(e) =>
                            //   handleCheckboxChange(row.id, e.target.checked)
                            // }
                          />
                        </td>
                        <td>{row.id}</td>
                        <td>{row.title}</td>
                        <td>{row.connectedParty}</td>
                        <td>{formatDatetoISO(row.duedate)}</td>
                        <td>
                          <Badge
                            variant="light"
                            color={getStatusColor(row.status)}
                          >
                            {getStatusText(row.status)}
                          </Badge>
                        </td>

                        <td>
                          <div className="flex flex-row mr-4">
                            <ActionIcon
                              onClick={() => {
                                onViewClick(row.id);
                              }}
                              color="white"
                            >
                              <IconEye size={24} />
                            </ActionIcon>
                            {/* )} */}
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-row mr-4">
                            <ActionIcon
                              onClick={() => {
                                setAhId(row.id);
                                setAhModal(true);
                              }}
                              color="white"
                            >
                              <IconHistory size={24} />
                            </ActionIcon>

                            {/* )} */}
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div className="flex flex-row mr-4">
                            {row.extraCol3 !== "" && (
                              <ActionIcon color="white">
                                <IconInfoCircle
                                  color={infoColor(row.extraCol3)}
                                  size={24}
                                />
                              </ActionIcon>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
          <div className="flex flex-row justify-center my-4">
            <Pagination
              page={page}
              onChange={(page) => {
                setPage(page);
              }}
              total={Math.ceil(configs.obligations.length / 10)}
            />
          </div>
        </>
      )}
      <Modal
        opened={ahModal}
        onClose={() => {
          setAhModal(false);
          setAhId(0);
        }}
        title="oblligation action history"
        size="calc(80vw)"
      >
        <ObligationTranHistoryModal id={ahId} />
      </Modal>
    </div>
  );
};

export default AssignedObligations;
