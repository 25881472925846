import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import SideNav from "./pages/SideNav";
import AssignedObligations from "./pages/AssignedObligations";
import CompletedObligation from "./pages/CompletedObligation";
import ViewObligation from "./pages/ViewObligation";
import CreateNewObligation from "./pages/CreateNewObligation";
import DocsLocker from "./pages/DocsLocker";
import ViewAgreement from "./pages/ViewAgreement";
import "./App.css";
import TeamManager from "./pages/TeamManager";
import ViewBu from "./pages/ViewBu";
import DashboardCompliance from "./pages/DashboardCompliance";
import OverDueCompliance from "./pages/OverDueCompliance";
import Notifications from "./pages/Notifications";
import ViewMasterObligation from "./pages/ViewMasterObligation";
import ActionedObligations from "./pages/ActionedObligations";
import { useDispatch, useSelector } from "react-redux";
import selectUserData from './redux/authSlice'
import Acceptobligation from "./pages/AcceptObligation";

const App = () => {
  // const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          {/* Redirect to dashboard if userData exists, otherwise redirect to login */}
          <Route
            path="/"
            element={
              userData ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
            }
          />

          {/* Public route for login */}
          {!userData && <Route path="/login" element={<Login />} />}

          {/* Protected routes, only accessible if userData exists */}
          {userData && (
            <>
              <Route path="/login" element={<Navigate to="/dashboard" />} />
              <Route
                path="/assigned"
                element={<SideNav appContent={<AssignedObligations />} />}
              />
              <Route
                path="/acceptobligation"
                element={<SideNav appContent={<Acceptobligation />} />}
              />
              <Route
                path="/obligationtracker"
                element={<SideNav appContent={<CompletedObligation />} />}
              />
              <Route
                path="/dashboard"
                element={<SideNav appContent={<DashboardCompliance />} />}
              />
              <Route
                path="/overdue"
                element={<SideNav appContent={<OverDueCompliance />} />}
              />
              <Route
                path="/viewobligation/:id"
                element={<SideNav appContent={<ViewObligation />} />}
              />
              <Route
                path="/new"
                element={<SideNav appContent={<CreateNewObligation />} />}
              />
              <Route
                path="/docslocker"
                element={<SideNav appContent={<DocsLocker />} />}
              />
              <Route
                path="/viewagreement/:id"
                element={<SideNav appContent={<ViewAgreement />} />}
              />
              <Route
                path="/viewbu/:id"
                element={<SideNav appContent={<ViewBu />} />}
              />
              <Route
                path="/viewmasterobligation/:id"
                element={<SideNav appContent={<ViewMasterObligation />} />}
              />
              <Route
                path="/team"
                element={<SideNav appContent={<TeamManager />} />}
              />
              <Route
                path="/notifications"
                element={<SideNav appContent={<Notifications />} />}
              />
              <Route
                path="/actioned"
                element={<SideNav appContent={<ActionedObligations />} />}
              />
            </>
          )}

          {/* Redirect all other paths to login if userData is not present */}
          {!userData && <Route path="*" element={<Navigate to="/login" />} />}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
