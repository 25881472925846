import React, { useEffect, useState } from "react";
import { getMasterObligation } from "../apis/userApis";
import { useParams } from "react-router-dom";
import { formatDatetoISO, getActFrequencyText, getDepartmentText, getRecFrequencyText } from "./utilities";
import { Divider, Text } from "@mantine/core";
import BusinessUnitName from "../componenets/getBuName";
import { useDispatch, useSelector } from 'react-redux';

const ViewMasterObligation = () => {
  const { id } = useParams();
  const [config, setConfig] = useState({
    loading: true,
    obligation: null,
  });
  useEffect(() => {
    const getMasterObligationDetails = async () => {
      const response =await getMasterObligation({ id });
      if (response.success) {
        setConfig({
          loading: false,
          obligation: response.obligation,
        });
      } else {
        setConfig({
          ...config,
          loading: false,
        });
      }
    };
    getMasterObligationDetails();
  }, []);

  return (
  <div>
    {!config.loading && config.obligation && (
        <>
          {/* <Text className="m-4 font-bold text-md">obligation {id} details</Text> */}

          {!config.loading && (
            <>
              <div className="grid grid-cols-2 gap-4 m-4">
                <div className="flex justify-start">
                  <Text className=" font-semibold ">
                    obligation-{config.obligation.id}
                  </Text>
                </div>
                
                <div className="flex justify-end">
                  <Text className=" font-semibold text-sm">
                    <span className="font-normal">created on :</span>
                    {formatDatetoISO(config.obligation.createdAt)}
                  </Text>
                </div>

                
              </div>
              <Text className="font-semibold mt-6 mx-4 mb-4">Agreement Details:</Text>
              <div className="grid grid-cols-4 gap-4 m-4 text-center">
                <div>
                  <Text className="font-light">department</Text>
                  <Text className="">
                    {getDepartmentText(config.obligation.department)}
                  </Text>
                </div>
                <div>
                  <Text className="font-light">AIN</Text>
                  <Text className="">
                    {config.obligation.local && "local-"}
                    {config.obligation.connectedAgreement}
                  </Text>
                </div>
                <div>
                  <Text className="font-light">agreement type</Text>
                  <Text className="">
                    {config.obligation.extraCol1}
                  </Text>
                </div>
                <div>
                  <Text className="font-light">business unit</Text>
                  <Text className="">
                    <BusinessUnitName businessUnitId={config.obligation.businessUnit} />
                  </Text>
                </div>
              </div>
              <Text className="m-4 font-semibold">Obligation Details: </Text>
              <div className="grid grid-cols-4 gap-4 m-4 text-center">
              <div>
                  <Text className="font-light">cl no.</Text>
                  <Text className="">
                    {config.obligation.clNo}
                  </Text>
                </div>
              <div>
                  <Text className="font-light">title</Text>
                  <Text className="">
                    {config.obligation.title}
                  </Text>
                </div>
                <div>
                  <Text className="font-light">oblligation</Text>
                  <Text className="">
                    {config.obligation.desc}
                  </Text>
                </div>
                {config.obligation.actFrequency !== 1 &&
                <div>
                  <Text className="font-light">rec. frequency</Text>
                  <Text className="">{getRecFrequencyText(config.obligation.recFrequency)}</Text>
                </div>}
                
                <div>
                  <Text className="font-light">act. frequency</Text>
                  <Text className="">{getActFrequencyText(config.obligation.actFrequency)}</Text>
                </div>
                <div>
                  <Text className="font-light">reminder time</Text>
                  <Text className="">
                    {config.obligation.reminderTime}-days
                  </Text>
                </div>
                <div>
                  <Text className="font-light">due date</Text>{" "}
                  <Text>
                    {formatDatetoISO(config.obligation.endDate)}
                  </Text>
                </div>
              </div>
              <Divider className="m-4"/>
              
            </>
          )}
          
          
        </>
      )}
  </div>
)};

export default ViewMasterObligation;
