import {
  Button,
  Divider,
  SegmentedControl,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import {
  createObligationApi,
  getAttacchedFiles,
  getFile,
} from "../apis/userApis";
import { converDateToISO, convertToISO8601 } from "./utilities";
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from "../redux/authSlice";

const LegacyAgreementForm2 = ({ setUiConfigs, details, view }) => {
  const [createobligation, setCreateObligation] = useState(false);
  const [file, setFile] = useState(null);
  console.log(details);
  const dispatch = useDispatch();
const userData = JSON.parse(localStorage.getItem("userData"));
  //   const currentUrl = window.location.href;
  // console.log(currentUrl);

  const [url, setUrl] = useState(null);
  const [mimeType, setMimeType] = useState(null);
  useEffect(() => {
    const attachedFile = async () => {
      const response = await getFile({ id: details.agreementId });
      console.log("response: ", response);
      setFile(response.file);
    };
    attachedFile();
  }, [details.agreementId]);

  const [obligationData, setObligationData] = useState({
    clNo: "",
    title: "",
    desc: "",
    actFrequency: 1,
    recFrequency: 7,
    startDate: "",
    endDate: details.endDate,
    department: 1,
    reminderTime: 1,
    remarks: "",
    businessUnit: details.businessUnit,
    local: details.local,
    connectedAgreement: details.agreementId,
    connectedParty: details.secondParty,
    extraCol1: details.typeOfAgreement,
    createdBy: userData.name,
    createdId: userData.id,
    createdByEmail: userData.email,
  });
  AWS.config.update({
    accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KAY_ID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`,
    region: `${process.env.REACT_APP_REGION}`,
  });
  const s3 = new AWS.S3();
  console.log(details);

  useEffect(() => {
    if (file) {
      const intialurl = file.hostedLink;
      console.log("intialUrl = ", intialurl);
      setMimeType(file.mimeType);
      const key = file.extraCol1;
      const params = {
        Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
        Key: key,
        Expires: 600 * 60, // URL will expire in 10 hour
      };
      const previewurl = s3.getSignedUrl("getObject", params);
      console.log("Presigned URL:", previewurl);
      setUrl(previewurl);
    }
  }, [file]);
  console.log("file: ", file);

  const createNewObligation = async (e) => {
    e.preventDefault();
    const response = await createObligationApi({ body: obligationData });

    if (response.success) {
      console.log("obligation created");
      setCreateObligation(false);
      setObligationData({
        clNo: "",
        title: "",
        desc: "",
        actFrequency: 1,
        recFrequency: 7,
        startDate: "",
        endDate: details.endDate,
        department: 1,
        reminderTime: 1,
        remarks: "",
        businessUnit: details.businessUnit,
        local: details.local,
        connectedAgreement: details.agreementId,
        connectedParty: details.secondParty,
        extraCol1: details.typeOfAgreement,
        createdBy: userData.name,
        createdId: userData.id,
        createdByEmail: userData.email,
      });
    }
  };

  console.log("obligation data: ", obligationData);
  return (
    <>
      <div>
        {file && url && mimeType && (
          <embed
            src={url}
            type={mimeType}
            width="100%"
            height="400px"
            title="Agreement View"
          />
        )}
        <Button onClick={() => setCreateObligation(true)} className="mt-2">
          add obligation
        </Button>
        {createobligation && (
          <>
            <form
              onSubmit={(e) => createNewObligation(e)}
              className="flex flex-row gap-1 mt-2"
            >
              <TextInput
                required
                placeholder="clause no."
                label="clause no."
                className="text-center rounded-sm  w-1/12"
                onChange={(e) =>
                  setObligationData({ ...obligationData, clNo: e.target.value })
                }
              />
              <TextInput
                required
                placeholder="title"
                label="title"
                className="text-center rounded-sm w-2/12"
                onChange={(e) =>
                  setObligationData({
                    ...obligationData,
                    title: e.target.value,
                  })
                }
              />
              <TextInput
                required
                placeholder="description"
                label="description"
                className="text-center rounded-sm w-2/12"
                onChange={(e) =>
                  setObligationData({ ...obligationData, desc: e.target.value })
                }
              />
              <Select
                required
                label="act. frequency"
                className="text-center w-1/12"
                placeholder="select act. frequency"
                data={[
                  { label: "one-time", value: 1 },
                  { label: "on-going", value: 2 },
                  { label: "reccuring", value: 3 },
                ]}
                onChange={(val) =>
                  setObligationData({ ...obligationData, actFrequency: val })
                }
              />
              {obligationData.actFrequency !== 1 && (
                <Select
                  required
                  label="rec. frequency"
                  className="text-center w-1/12"
                  placeholder="select rec. frequency"
                  data={[
                    { label: "weekly", value: 7 },
                    { label: "monthly", value: 30 },
                    { label: "quarterly", value: 90 },
                    { label: "annually", value: 365 },
                  ]}
                  onChange={(val) =>
                    setObligationData({ ...obligationData, recFrequency: val })
                  }
                />
              )}
              <DatePicker
                required
                minDate={new Date()}
                label="start date"
                className="text-center w-1/12"
                placeholder="start date"
                onChange={(val) =>
                  setObligationData({
                    ...obligationData,
                    startDate: convertToISO8601(converDateToISO(val)),
                  })
                }
              />
              {obligationData.actFrequency === 1 &&
                <DatePicker
                minDate={new Date()}
                required
                label="end date"
                className="text-center w-1/12"
                placeholder="end date"
                onChange={(val) =>
                  setObligationData({ ...obligationData, endDate: val })
                }
              />
              }
              
              <Select
                required
                label="department"
                className="text-center w-1/12"
                placeholder="department"
                data={[
                  { label: "Finance", value: 1 },
                  { label: "HR", value: 2 },
                  { label: "IT", value: 3 },
                  { label: "Taxation", value: 4 },
                ]}
                onChange={(val) =>
                  setObligationData({ ...obligationData, department: val })
                }
              />
              <Select
                required
                label="reminder time"
                className="text-center w-1/12"
                placeholder="reminder time"
                data={[
                  { label: "1-day", value: 1 },
                  { label: "2-days", value: 2 },
                  { label: "3-days", value: 3 },
                  { label: "4-days", value: 4 },
                  { label: "5-days", value: 5 },
                  { label: "6-days", value: 6 },
                  { label: "7-days", value: 7 },
                  { label: "15-days", value: 15 },
                  { label: "30-days", value: 30 },
                  { label: "45-days", value: 45 },
                  { label: "60-days", value: 60 },
                  { label: "90-days", value: 90 },
                ]}
                onChange={(val) =>
                  setObligationData({ ...obligationData, reminderTime: val })
                }
              />
              <Button type="submit" className="mt-6">
                save
              </Button>
            </form>
          </>
        )}
        <div className="flex flex-row justify-between">
          {view ? (
            <div></div>
          ) : (
            <Button
              style={{
                backgroundColor: "#46BDE1",
              }}
              onClick={() => setUiConfigs({ currentStep: 0 })}
              className="mt-3"
            >
              view agreement
            </Button>
          )}
          <Button
            style={{
              backgroundColor: "#46BDE1",
            }}
            onClick={() => setUiConfigs({ currentStep: 2 })}
            className="mt-3"
          >
            view obligations
          </Button>
        </div>
      </div>
    </>
  );
};

export default LegacyAgreementForm2;
