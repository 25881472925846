import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { generateRandomColors } from "../pages/utilities";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart2 = ({filtersForData, setFiltersForData, labels, datafor,businessUnitData }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "obligations",
        data: datafor,
        backgroundColor: generateRandomColors(businessUnitData.length),
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const label = data.labels[elementIndex].split("/")[0];
        setFiltersForData({...filtersForData, businessUnit: businessUnitData.find(unit => unit.name === label).businessUnit})
      }
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  return (
    <div style={{height: "200px", width: "100%" }}>
      <Doughnut data={data} options={options} />
      </div> 
  );
};

export default DoughnutChart2;
