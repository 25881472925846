import React, { useEffect, useState } from "react";
import { fetchBuOptions, getAllObligations, getNamesofAgreement } from "../apis/userApis";
import {
  ActionIcon,
  Badge,
  Button,
  Modal,
  Pagination,
  Select,
  Table,
  Text,
} from "@mantine/core";
import { getComplianceBadgeColor, getDateStatus, getDepartmentText, getStatusColor, getStatusText, infoColor } from "./utilities";
import { IconEye, IconHistory, IconInfoCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import ObligationTranHistoryModal from "./ObligationTranHistoryModal";
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from "../redux/authSlice";

const CompletedObligation = () => {
  const dispatch = useDispatch();
const userData = JSON.parse(localStorage.getItem("userData"));
  const [ahModal, setAhModal] = useState(false);
  const [ahId, setAhId] = useState(0);
  const navigate = useNavigate();
  let filterForUser ={
    compliant: null,
    department: (userData.role === "Ob-CXO" || userData.role === "Ob-DeptAdmin") ? userData.department : null,
    businessUnit: (userData.role === "Ob-COO" ) ? userData.businessUnit : null,
    extraCol1: null,
  }
  const [filters, setFilters] = useState(filterForUser);
  const [configs, setConfigs] = useState({
    loading: true,
    obligations: null,
    count: 0,
  });
  const [buData, setBuData] = useState(null);
  const [categoryOptions, setCategoryOptions]= useState(["NDA", "Service Agreement"]);

  useEffect(()=>{
    const getNames = async()=>{
      const response = await getNamesofAgreement();
      if(response.success){
        setCategoryOptions(response.namesOfAgreement)
      }
    }
    getNames();
  },[])
  useEffect(()=>{
    const getBuData = async()=>{
      const response = await fetchBuOptions();
      setBuData (response.buData);
    }
    getBuData();
  }, []);
  const [applyfilters, setApplyFilters] = useState(true);
  const [page, setPage] = useState(1);
  let sr = (page - 1) * 10 + 1;
  useEffect(() => {
    const getObligation = async () => {
      const filterdata = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null)
      );
      const response = await getAllObligations({
        page: page,
        filters: filterdata,
      });
      setConfigs({
        loading: false,
        obligations: response.obligations,
        count: response.count,
      });
      console.log("response: ", response);
    };
    getObligation();
  }, [page, applyfilters]);
  const onViewClick = ({ id }) => {
    navigate(`/viewobligation/${id}`);
  };

  return (
    <>
      <Text className="font-semibold text-xl m-4">obligation tracker</Text>

      <div className="grid grid-cols-6 gap-4 m-4">
        <Select
          placeholder="select compliance"
          label="compliance"
          value={filters.compliant}
          data={[
            { label: "non-compliant", value: "non-compliant" },
            {
              label: "partially-compliant",
              value: "partially-compliant",
            },
            { label: "compliant", value: "compliant" },
          ]}
          onChange={(val) => setFilters({ ...filters, compliant: val })}
        />
        {userData.role !=="Ob-DeptAdmin" && userData.role !== "Ob-CXO" &&
        <Select
          label="department"
          placeholder="select department"
          data={[
            { label: "Finance", value: 1 },
            { label: "HR", value: 2 },
            { label: "IT", value: 3 },
            { label: "Taxation", value: 4 },
          ]}
          onChange={(val) => setFilters({ ...filters, department: val })}
          value={filters.department}
        />
        }
        {buData!==null && userData.role !=="Ob-COO"  && 
        <Select
          label="business unit"
          placeholder="select business unit"
          value={filters.businessUnit}
          data={buData}
          onChange={(val) => setFilters({ ...filters, businessUnit: val })}
        />}
        <Select
          label="agreement type"
          placeholder="agreement type"
          value={filters.extraCol1}
          data={categoryOptions}
          onChange={(val) => setFilters({ ...filters, extraCol1: val })}
        />
      </div>
      <div className="m-4">
        <Button
          onClick={() => setApplyFilters(!applyfilters)}
          className="mr-4"
          color="green"
        >
          search
        </Button>
        <Button
          onClick={() => {
            setFilters(filterForUser);
            setApplyFilters(!applyfilters);
          }}
          color="red"
        >
          clear
        </Button>
      </div>
      <div className="m-4">
        {!configs.loading && configs.obligations && (
          <>
            <Table striped>
              <thead className="text-center">
                <tr>
                  <th style={{ textAlign: "center" }}>id</th>
                  <th style={{ textAlign: "center" }}>title</th>
                  <th style={{ textAlign: "center" }}>obligation</th>
                  <th style={{ textAlign: "center" }}>agreement type</th>
                  <th style={{ textAlign: "center" }}>business unit</th>
                  <th style={{ textAlign: "center" }}>second party</th>
                  <th style={{ textAlign: "center" }}>department</th>
                  <th style={{ textAlign: "center" }}>compliance</th>
                  <th style={{ textAlign: "center" }}>status</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {configs.obligations.map((obligation) => (
                  <tr key={obligation.id}>
                    <td style={{ textAlign: "center" }}>{obligation.id}</td>
                    <td style={{ textAlign: "center" }}>{obligation.title}</td>
                    <td style={{ textAlign: "center" }}>{obligation.desc}</td>
                    <td style={{ textAlign: "center" }}>
                      {obligation.extraCol1}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {obligation.businessUnit}{" "}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {obligation.connectedParty}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {getDepartmentText(obligation.department)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Badge
                        variant="light"
                        color={getComplianceBadgeColor(obligation.compliant)}
                      >
                        {obligation.compliant}
                      </Badge>
                    </td>
                    <td>
                    <Badge variant="light" color={getStatusColor(getDateStatus(obligation.createdAt, obligation.duedate, obligation.reminderTime ))}>
                      {getStatusText(getDateStatus(obligation.createdAt, obligation.duedate, obligation.reminderTime ))}
                    </Badge>
                  </td>
                    <td style={{ textAlign: "center" }}>
                      <div className="flex flex-row mr-4">
                        <ActionIcon
                          onClick={() => {
                            onViewClick({ id: obligation.id });
                          }}
                          color="white"
                        >
                          <IconEye size={24} />
                        </ActionIcon>
                        {/* )} */}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div className="flex flex-row mr-4">
                        <ActionIcon
                          color="white"
                          onClick={() => {
                            setAhId(obligation.id);
                            setAhModal(true);
                          }}
                        >
                          <IconHistory size={24} />
                        </ActionIcon>
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div className="flex flex-row mr-4">
                        {obligation.extraCol3 !== "" && 
                        <ActionIcon
                          color="white"
                        >
                          <IconInfoCircle color={infoColor(obligation.extraCol3)} size={24} />
                        </ActionIcon>}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={page}
                onChange={(page) => {
                  setPage(page);
                }}
                total={Math.ceil(configs.count / 10)}
              />
            </div>
          </>
        )}
        <Modal
        opened={ahModal}
        onClose={() => {
          setAhModal(false);
          setAhId(0);
        }}
        title="oblligation action history"
        size="calc(80vw)"
      >
        <ObligationTranHistoryModal id={ahId} />
      </Modal>
      </div>
    </>
  );
};

export default CompletedObligation;
